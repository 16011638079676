<template>
  <footer id="page-footer">
    <div class="content py-3">
      <div class="row fs-sm">
        <div class="col-sm-6 order-sm-2 py-1 text-center text-sm-end">
          <a href="https://beian.miit.gov.cn/" target="_blank" class="text-muted fw-semibold"></a>
        </div>
        <div class="col-sm-6 order-sm-1 py-1 text-center text-sm-start">
          © <span data-toggle="year-copy" class="js-year-copy-enabled">2022</span> <a class="fw-semibold"
                                                                                      href="#"
                                                                                      target="_blank">Emo-Tool</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>

</style>
