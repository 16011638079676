<template>
  <div
    id="page-container"
    class="sidebar-o enable-page-overlay side-scroll page-header-modern main-content-boxed side-trans-enabled"
  >
    <Aside :user="user" />
    <Header :user="user" />
    <main id="main-container">
      <router-view v-if="isRouterAlive" />
    </main>
    <Footer />
  </div>
</template>
<script>
import Aside from "@/components/Aside";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { getUser } from "@/api/user";
export default {
  name: "Manage",
  mounted() {
    let script = document.createElement("script");
    script.src = "/static/js/codebase.app.min-5.1.js";
    document.body.appendChild(script);
  },

  provide() {
    return {
      reload: this.reload,
      reloadUser: this.reloadUser,
    };
  },
  watch: {
    $route() {
      if (window.innerWidth <= 991) {
        Codebase.layout("sidebar_close");
      }
    },
  },

  data() {
    return {
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {},
      isRouterAlive: true,
    };
  },
  components: {
    Footer,
    Aside,
    Header,
  },

  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },

    reloadUser() {
      getUser().then((res) => {
        res.token = JSON.parse(localStorage.getItem("user")).token;
        localStorage.setItem("user", JSON.stringify(res));
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true;
        });
      });
    },
  },
};
</script>

<style>
@media screen and (max-width: 500px) {
  .el-message-box {
    width: 300px !important;
  }
}

@media screen and (max-width: 500px) {
  .el-message {
    min-width: 300px !important;
  }
}

@media screen and (max-width: 500px) {
  .el-dialog__wrapper .el-dialog {
    width: 300px !important;
  }

  .el-dialog__body {
    padding: 10px 20px !important;
  }

  .el-form-item__label {
    width: 75px !important;
  }

  .el-select,
  .el-input {
    width: 180px !important;
  }
}
</style>
