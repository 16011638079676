<template>
  <header id="page-header">
    <div class="content-header">
      <div class="space-x-1">
        <button
          type="button"
          class="btn btn-sm btn-alt-secondary"
          data-toggle="layout"
          data-action="sidebar_toggle"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn btn-sm btn-alt-secondary"
            id="page-header-themes-dropdown"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-paint-brush"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg p-0"
            aria-labelledby="page-header-themes-dropdown"
          >
            <div class="p-3 bg-body-light rounded-top">
              <h5 class="h6 text-center mb-0">界面颜色</h5>
            </div>
            <div class="p-3">
              <div class="row g-0 text-center">
                <div class="col-2">
                  <a
                    class="text-default"
                    data-toggle="theme"
                    data-theme="default"
                    href="javascript:void(0)"
                  >
                    <i class="fa fa-2x fa-circle"></i>
                  </a>
                </div>
                <div class="col-2">
                  <a
                    class="text-elegance"
                    data-toggle="theme"
                    data-theme="/static/css/themes/elegance.min-5.0.css"
                    href="javascript:void(0)"
                  >
                    <i class="fa fa-2x fa-circle"></i>
                  </a>
                </div>
                <div class="col-2">
                  <a
                    class="text-pulse"
                    data-toggle="theme"
                    data-theme="/static/css/themes/pulse.min-5.0.css"
                    href="javascript:void(0)"
                  >
                    <i class="fa fa-2x fa-circle"></i>
                  </a>
                </div>
                <div class="col-2">
                  <a
                    class="text-flat"
                    data-toggle="theme"
                    data-theme="/static/css/themes/flat.min-5.0.css"
                    href="javascript:void(0)"
                  >
                    <i class="fa fa-2x fa-circle"></i>
                  </a>
                </div>
                <div class="col-2">
                  <a
                    class="text-corporate"
                    data-toggle="theme"
                    data-theme="/static/css/themes/corporate.min-5.0.css"
                    href="javascript:void(0)"
                  >
                    <i class="fa fa-2x fa-circle"></i>
                  </a>
                </div>
                <div class="col-2">
                  <a
                    class="text-earth"
                    data-toggle="theme"
                    data-theme="/static/css/themes/earth.min-5.0.css"
                    href="javascript:void(0)"
                  >
                    <i class="fa fa-2x fa-circle"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="space-x-1">
        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn btn-sm btn-alt-secondary"
            id="page-header-user-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-user d-sm-none"></i>
            <span class="d-none d-sm-inline-block fw-semibold">{{
              user.nickname
            }}</span>
            <i class="fa fa-angle-down opacity-50 ms-1"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-md dropdown-menu-end p-0"
            aria-labelledby="page-header-user-dropdown"
          >
            <div class="px-2 py-3 bg-body-light rounded-top">
              <h5 class="h6 text-center mb-0">
                {{ user.nickname }}
              </h5>
            </div>
            <div class="p-2">
              <router-link
                class="dropdown-item d-flex align-items-center justify-content-between space-x-1"
                to="/user"
              >
                <span>个人资料</span>
                <i class="fa fa-fw fa-user opacity-25"></i>
              </router-link>

              <div class="dropdown-divider"></div>
              <a
                href="javascript:"
                @click="logout()"
                class="dropdown-item d-flex align-items-center justify-content-between space-x-1"
              >
                <span>注销登录</span>
                <i class="fa fa-fw fa-sign-out-alt opacity-25"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="page-header-loader" class="overlay-header bg-primary">
      <div class="content-header">
        <div class="w-100 text-center">
          <i class="far fa-sun fa-spin text-white"></i>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { logout } from "@/api/user";
export default {
  name: "Header",
  props: {
    user: Object,
  },
  methods: {
    logout() {
      this.$confirm("你确定退出登录吗?", "Warning", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        logout()
          .then(() => {
            this.$message({
              type: "success",
              message: "注销成功",
            });
            setTimeout(() => {
              this.$store.commit("logout");
            }, 1000);
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: "注销失败",
            });
          });
      });
    },
  },
};
</script>

<style scoped></style>
